import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class CitiesPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.citiesRead,
    );
    this.import = permissionChecker.match(
      Permissions.values.citiesImport,
    );
    this.autocomplete = permissionChecker.match(
      Permissions.values.citiesAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.citiesCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.citiesEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.citiesDestroy,
    );
  }
}
