//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { CitiesModel } from '@/modules/cities/cities-model';
import i18n from '@/vueI18n';
import firebase from 'firebase/app'
import 'firebase/firestore'

const { fields } = CitiesModel;
const formSchema = new FormSchema([
  // fields.id,
  fields.name,
  // fields.regions,
]);

export default {
  name: 'app-regions-form-modal',

  props: ['id','city', 'visible'],

  async created() {
    if (this.isEditing) {
      await this.doFind({id:this.id,city:this.city});
    } else {
      await this.doNew();
    }
    this.model = formSchema.initialValues(this.record || {});
    if (!this.isEditing) {
      this.model.name = {
        en: undefined,
        ar: undefined,
      }
    }
    else{
      this.model.oldName = this.model.name.en
    }

    // await firebase.firestore().collection('city').onSnapshot({ includeMetadataChanges: true }, async () => {
      this.cities = []
      this.loadingCities = true
      const response = await firebase.firestore().collection("city").get()
      response.forEach(async (doc) => {
        let city = doc.data()
        city['id'] = doc.id
        if(city.name.en === this.city){
          this.cityId = doc.id
        }
        
        this.cities.push(city)
      })
      this.loadingCities = false
    // })
  },

  data() {
    return {
      cityId: undefined,
      cities: [],
      citiesOptions: [],
      loadingCities: false,
      successDialog: false,
      model: null,
      rules: formSchema.rules(),
    };
  },

  computed: {
    ...mapGetters({
      record: 'citiesRegions/form/record',
      findLoading: 'citiesRegions/form/findLoading',
      saveLoading: 'citiesRegions/form/saveLoading',
      is_screen_xs: 'layout/is_screen_xs',
    }),
    dialogVisible: {
      get: function() {
        return this.visible;
      },

      set: function(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    fields() {
      return fields;
    },
    isEditing() {
      return !!this.id;
    },
    citiesList() {
      return this.cities.map(item => {
        return {
          label: item.name[this.language],
          value: item.id, 
        }
      })
    },
    language(){
      return i18n.locale;
    }
  },
  
  methods: {
    ...mapActions({
      doFind: 'citiesRegions/form/doFindRegion',
      doNew: 'citiesRegions/form/doNew',
      doUpdate: 'citiesRegions/form/doUpdateRegion',
      // doCreate: 'citiesRegions/form/doCreate',
      doCreate: 'citiesRegions/form/doCreateRegion',
      doFetchCitiesAndRegions: 'citiesRegions/list/doFetchCitiesAndRegions',
      doFetchRegions: 'citiesRegions/list/doFetchRegions',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    doReset() {
      this.model = formSchema.initialValues(this.record);
    },
    doCancel() {
      this.dialogVisible = false;
    },
    OnClear() {
      this.model.sellerParts = []
    },
    filterGovernorateFn (val, update) {
      if (val === '') {
        update(() => {
          this.citiesOptions = this.citiesList

          // with Quasar v1.7.4+
          // here you have access to "ref" which
          // is the Vue reference of the QSelect
        })
        return
      }

      update(() => {
        const needle = val.toLowerCase()
        this.citiesOptions = this.citiesList.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
      })
    },

    async doSubmit() {
      let { id, ...values } = formSchema.cast(this.model);
      if (this.isEditing) {
        id = this.cityId
        await this.doUpdate({
          id,
          values,
        });
        this.$emit('close');
      } else {
        const isCreated = await this.doCreate({
          cityId: this.cityId,
          values,
        });
        // await this.doFetchCitiesAndRegions()
        await this.doFetchRegions()
        this.$emit('close');
        if (isCreated) {
          this.successDialog = true
        }
      }
    },
  },
};
